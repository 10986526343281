import Swiper, { Navigation, EffectFade } from 'swiper';

class Doubleslider {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.initSlider();
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Navigation, EffectFade ],
            effect: "fade",
            direction: 'horizontal',
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }
        } );
    }


}


export const doubleslider = new Doubleslider();