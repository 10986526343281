export default class Lottie {

    constructor( element ) {
        this.element = element;

        this.init();
    }

    init() {
        this.element.src = this.element.getAttribute('data-src');
        this.element.load(this.element.getAttribute('data-src'));
    }
}