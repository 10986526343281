import Swiper, { Autoplay, Navigation, EffectFade, EffectCoverflow, EffectFlip, EffectCards } from 'swiper';

class Financecarousel {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.linkitems = document.querySelectorAll('[data-slideto]');

        this.initSlider();
        this.addEventListener()
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Navigation, EffectFade, Autoplay ],
            effect: "fade",
            direction: 'horizontal',
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            on: { 
                realIndexChange: (swiper) => this.setActiveClass(this.gswiper.realIndex)
            } 
        } );

        this.setActiveClass(this.gswiper.realIndex)
        

    }

    addEventListener() {
        this.linkitems.forEach( ( item ) => {
            item.addEventListener( 'click', ( event ) => { this.goToSlide(item); } )
        });
    }

    goToSlide(item) {
        console.log(item.dataset.slideto);
        this.gswiper.slideTo(item.dataset.slideto, 0);
    }

    setActiveClass(activeSlideIndex) {
        this.linkitems.forEach( ( item ) => {
            if (item.dataset.slideto == activeSlideIndex) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        });

    }

}


export const financecarousel = new Financecarousel();