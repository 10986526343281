import inView from 'in-view';

export default class InView {

    constructor( selector ) {

        inView.offset( 100 );
        inView( selector ).on( 'enter', el => { el.classList.add( 'in-view' ); } );

    }

}