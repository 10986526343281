import Swiper, { Navigation } from 'swiper';

class Lightbox {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.grid = document.querySelector('[data-image-grid');
        this.griditems = this.grid.querySelectorAll('[data-item]');
        this.lightbox = document.querySelector('[data-lightbox]');
        this.slider = document.querySelector('[data-lightbox-slider]');

        this.body = document.body;


        this.addEventListeners();
    }


    addEventListeners() {
        this.griditems.forEach( ( item ) => {
            item.addEventListener( 'click', ( event ) => { this.openLightbox(item); } )
        });

        this.slider.addEventListener( 'click', ( event ) => {
                if (event.target.id == 'next') {
                    this.gswiper.slideNext();
                } else if (event.target.id == 'previous') {
                    this.gswiper.slidePrevious();
                } else {
                    this.closeLightbox(); 
                }
            } 
        );
    }

    openLightbox(item) {
        this.initSlider(item.dataset.item);
        this.lightbox.dataset.lightbox = 'visible';
        this.body.dataset.scroll = "no-scroll";
    }

    closeLightbox(item) {
        this.gswiper.destroy();
        this.lightbox.dataset.lightbox = 'hidden';
        this.body.dataset.scroll = "scroll";
    }

    initSlider(itemindex) {

        this.gswiper = new Swiper( this.slider, {
            modules: [ Navigation ],
            direction: 'horizontal',
            slidesPerView: 1,
            releaseOnEdges: true,
            loop: true,
            speed: 1000,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }
        } );

        this.gswiper.slideTo(itemindex);
    }


}


export const lightbox = new Lightbox();