import Swiper, { Autoplay } from 'swiper';

class Timelineslider {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.initSlider();
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Autoplay ],
            direction: 'horizontal',
            slidesPerView: 2,
            releaseOnEdges: true,
            loop: true,
            speed: 15000,
            autoheight: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            breakpoints: {
                374: {
                    slidesPerView: 1,
                },
                584: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        } );
    }


}


export const timelineslider = new Timelineslider();