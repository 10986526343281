import Swiper, { Scrollbar } from 'swiper';

class Photoslider {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.initSlider();
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Scrollbar ],
            direction: 'horizontal',
            slidesPerView: 2,
            releaseOnEdges: true,
            loop: true,
            breakpoints: {
                584: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                1600: {
                    slidesPerView: 5,
                },
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
              },
        } );
    }


}


export const photoslider = new Photoslider();