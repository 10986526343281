import "@lottiefiles/lottie-player";

import { logoslider } from './components/logo-slider';
import { carousel } from './components/carousel';
import { lightbox } from './components/lightbox';
import { photoslider } from './components/photo-slider';
import { doubleslider } from './components/double-slider';
import { newscarousel } from './components/news-carousel';
import { timelineslider } from './components/timeline-slider';
import { financecarousel } from './components/finance-carousel';



import Image from './components/image';
import Menu from './components/menu';
import Lottie from './components/lottie';


const logoSlider = document.querySelector( '[data-logo-slider]' );
const Carousel = document.querySelector( '[data-carousel]' );
const Lightbox = document.querySelector( '[data-images-carre]' );
const Photoslider = document.querySelector( '[data-photos-slider]' );

const doublesliders = document.querySelectorAll( '[data-double-slider]' );
const logosliders = document.querySelectorAll( '[data-logo-slider]' );
const newscarousels = document.querySelectorAll( '[data-news-carousel]' );
const timelineSliders = document.querySelectorAll( '[data-timeline-slider]' );
const financecarousels = document.querySelectorAll( '[data-finance-carousel]' );


const menuElement = document.querySelector('[data-hamburger]');
const images = document.querySelectorAll( '[data-image]' );
const lottieElements = document.querySelectorAll( '[data-lottie]' );


import InView from './components/in-view';




if ( Carousel ) {
    carousel.init( Carousel );
}

if ( Lightbox ) {
    lightbox.init( Lightbox );
}

if ( Photoslider ) {
    photoslider.init( Photoslider );
}

if ( logosliders ) {
    logosliders.forEach(element => {
        logoslider.init( element );
    })
}

if ( doublesliders ) {
    doublesliders.forEach(element => {
        doubleslider.init( element );
    })
}

if ( images ) {
    images.forEach(element => {
        const image = new Image( element );
    });
}

if ( menuElement ) {
    const menu = new Menu (menuElement);
}

if ( newscarousels ) {
    newscarousels.forEach(element => {
        newscarousel.init( element );
    })
}

if ( timelineSliders ) {
    timelineSliders.forEach(element => {
        timelineslider.init( element );
    })
}

if ( financecarousels ) {
    financecarousels.forEach(element => {
        financecarousel.init( element );
    })
}

if ( lottieElements ) {
    lottieElements.forEach(element => {
        const lottie = new Lottie( element );
    });
}

const inView = new InView( '[data-in-view]' );

