import Swiper, { Autoplay } from 'swiper';

class Newscarousel {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.initSlider();
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Autoplay ],
            direction: 'vertical',
            slidesPerView: 3,
            // releaseOnEdges: true,
            loop: true,
            speed: 5000,
            autoplay: {
                // delay: 3500,
                disableOnInteraction: false,
            }
        } );
    }


}


export const newscarousel = new Newscarousel();