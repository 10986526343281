import Swiper, { Autoplay } from 'swiper';

class Carousel {

    constructor() {
        this.element;
    }

    init( element ) {
        this.element = element;
        this.gswiper;
        this.initSlider();
    }

    initSlider() {
        this.gswiper = new Swiper( this.element, {
            modules: [ Autoplay ],
            direction: 'horizontal',
            slidesPerView: 1,
            releaseOnEdges: true,
            loop: true,
            speed: 5000,
            autoplay: {
                // delay: 3500,
                disableOnInteraction: false,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
            },
        } );
    }


}


export const carousel = new Carousel();