export default class Lottie {

    constructor( element ) {
        this.element = element;
        this.width;

        this.src;
        this.srcmob;
        this.srcset;
        this.srcsetmob;

        this.init();
        this.addEventListeners();
    }

    init() {

        this.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        this.src = this.element.getAttribute('data-src');
        this.srcmob = this.element.getAttribute('data-src-mobile');
        this.srcset = this.element.getAttribute('data-srcset');
        this.srcsetmob = this.element.getAttribute('data-srcset-mobile');

        if (this.width < 767) {
            this.element.src = this.srcmob;
            this.element.srcset = this.srcsetmob;
        } else {
            this.element.src = this.src;
            this.element.srcset = this.srcset;
        }
    }

    addEventListeners() {
        window.addEventListener( 'resize', (event) => {
            this.init();
        });
        window.addEventListener( 'orientationChange', (event) => {
            this.init();
        });
    }
}